<template>
<div class="auth-wrapper auth-v2">
    <b-overlay
                :show="show"
                rounded="sm"
                >
    <b-row class="auth-inner m-0">

        <!-- Brand logo-->
        <b-link class="brand-logo d-none d-lg-flex ">

            <h2 class="brand-text text-primary ml-1">
                CloudFit Pro
            </h2>
        </b-link>
        <!-- /Brand logo-->

        <!-- Left Text-->
        <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                <b-img fluid :src="imgUrl" alt="Register V2" />
            </div>
        </b-col>
        <!-- /Left Text-->

        <!-- Register-->
        <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
 
            <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                    Adventure starts here 🚀<br>
                    開始你簡單的管理旅程🚀
                </b-card-title>
                <b-card-text class="mb-2">
                    Make fitness management easy and fun!<br>
                    使你的管理更容易
                </b-card-text>

                <b-form-group label="申請類型 Account Type" label-for="account_type" class="mt-1">

                    <v-select v-model="form.account_type" :reduce="type => type.id" :options="account_type" :clearable="false" />
                </b-form-group>

                <b-form-group label="登入名稱 Login Name" label-for="login-username" class="mt-1">
                    <b-form-input id="login-username" v-model="form.username" name="login-username" placeholder="peterchan" />
                </b-form-group>

                <b-input-group>
                    <b-input-group-prepend is-text>
                        +852
                    </b-input-group-prepend>
                    <b-form-input placeholder="手提號碼" v-model="form.mobile" />
                    <b-input-group-append>
                        <b-button variant="outline-success" @click="send_verify_code()">
                            <font-awesome-icon icon="fa-brands fa-whatsapp" color="#25D366" />
                            取得驗證碼
                        </b-button>
                    </b-input-group-append>
                </b-input-group>

                <b-form-group label="驗證碼 Verification Code" label-for="verify-code" class="mt-1" v-if="code_sent">
                    <b-form-input id="verify-code" v-model="form.verify_code" name="verify-code" placeholder="······" />
                </b-form-group>

                <b-form-group label="登入密碼 Login Password" label-for="login-username" class="mt-1">
                    <b-input-group class="input-group-merge">
                        <b-form-input id="register-password" v-model="form.password" class="form-control-merge" :type="passwordFieldType" name="register-password" placeholder="············" />
                        <b-input-group-append is-text>
                            <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group label="再次輸入密碼 Confirm Password" label-for="login-username" class="mt-1">
                    <b-input-group class="input-group-merge">
                        <b-form-input id="confirm-password" v-model="form.confirm_password" class="form-control-merge" :type="passwordFieldType" name="register-password" placeholder="············" />
                        <b-input-group-append is-text>
                            <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>

                <div class="divider my-2">
                    <div class="divider-text">
                        業務資料
                    </div>
                </div>

                <b-form-group label="公司/業務名稱 Fitness Name * 必須英文" label-for="fitness-name" class="mt-1">
                    <b-form-input id="fitness-name" v-model="form.fitness_name" name="fitness-name" placeholder="ABC Fitness" />
                </b-form-group>
                <b-form-group label="公司/業務簡寫 Fitness Alias * 必須英文 , 最多4個英文字" label-for="fitness-alias" class="mt-1">
                    <b-form-input id="fitness-alias" v-model="form.fitness_alias" name="fitness-alias" placeholder="ABC" />
                </b-form-group>

                <b-form-group>
                    <b-form-checkbox id="register-privacy-policy" v-model="form.agree" name="checkbox-1">
                        我同意 <b-link>私隱條款及使用守則</b-link><br>
                        I agree to <b-link>privacy policy & terms</b-link>

                    </b-form-checkbox>
                </b-form-group>
                <!-- form -->

                <b-button variant="primary" block type="button" :disabled="show" @click="submit()">
                    確定 Sign up
                </b-button>

                <!-- divider -->
                <div class="divider my-2">
                    <div class="divider-text">
                        or
                    </div>
                </div>
                <p class="text-center mt-2">
                    <span>Already have an account? 已經擁有戶口?</span><br>
                    <b-link :to="{name:'auth-login-v2'}">
                        <span>&nbsp;Sign in </span><span>&nbsp;登入</span>
                    </b-link>
                </p>

            </b-col>
           
        </b-col>
        <!-- /Register-->
    </b-row>
</b-overlay>
</div>
</template>

<script>
/* eslint-disable global-require */
import {
    ValidationProvider,
    ValidationObserver
} from 'vee-validate'
import {
    BInputGroupPrepend,
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BOverlay,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
    BCardText,
} from 'bootstrap-vue'
import {
    required,
    email
} from '@validations'
import {
    togglePasswordVisibility
} from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import vSelect from 'vue-select'

export default {
    components: {
        vSelect,
        BInputGroupPrepend,
        BRow,
        BOverlay,
        BImg,
        BCol,
        BLink,
        BButton,
        BForm,
        BCardText,
        BCardTitle,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        // validations
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
          app_version : null,
          show: false,
            form: {
                account_type: 'individual',
                username: null,
                mobile: null,
                verify_code: null,
                password: null,
                confirm_password: null,
                agree: false
            },
            account_type: [{
                    id: 'individual',
                    label: '個人 Individual'
                },
                {
                    id: 'company',
                    label: '公司 Company'
                }
            ],
            code_sent: false,
            status: '',
            username: '',
            userEmail: '',
            password: '',
            sideImg: require('@/assets/images/pages/register-v2.svg'),
            // validation
            required,
            email,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    created() {
      this.app_version = process.env.VUE_APP_VERSION
    },
    methods: {
        send_verify_code() {
            this.$http.post(process.env.VUE_APP_API_BASE + '/account-register/code/', {
                    mobile: this.form.mobile
                })
                .then(res => {
                    if (res.data.result === true) {
                        const userData = res.data
                        this.code_sent = true
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-center',
                            props: {
                                title: `驗證碼已送出. Code sent.`,
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: `請檢查你的WhatsApp.`,
                            },
                        })
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-center',
                            props: {
                                title: 'Error',
                                icon: 'XIcon',
                                variant: 'danger',
                                text: res.data.message,
                            },
                        })
                    }
                })
        },
        login() {
            this.$http.defaults.headers.Version = this.app_version
            this.$http.post(process.env.VUE_APP_API_BASE + '/auth/', {
                    username: this.form.username,
                    password: this.form.password,
                    version: this.app_version
                })
                .then(res => {
                    if (res.data.result === true) {
                        const userData = res.data
                        this.$store.commit('center/UPDATE_CENTER_ID', userData.default_center_id)
                        this.$store.commit('center/UPDATE_CENTER_NAME', userData.default_center_name)
                        this.$http.defaults.headers.Token = res.data.token
                        this.$i18n.locale = userData.language
                       

                        localStorage.setItem('userData', JSON.stringify(userData))
                        this.$ability.update(userData.ability)
                        localStorage.setItem('reader_action', 'normal')
                        this.$router.replace('/apps/config')
                            .then(() => {

                            })
                        console.log(userData.ability)
                        //     localStorage.setItem('userData', JSON.stringify(userData))
                        //    this.$ability.update(userData.ability)
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-center',
                            props: {
                                title: 'Error',
                                icon: 'XIcon',
                                variant: 'danger',
                                text: res.data.message,
                            },
                        })
                    }
                })
        },
        submit() {
            this.show = true
            this.$http.post(process.env.VUE_APP_API_BASE + '/account-register/register/', this.form)
                .then(res => {
                    if (res.data.result === true) {
                        this.show = false
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-center',
                            props: {
                                title: `註冊成功.`,
                                icon: 'CheckIcon',
                                variant: 'success',
                                text: ``,
                            },
                        })
                        this.login()
                    } else {
                        this.show = false
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-center',
                            props: {
                                title: 'Error',
                                icon: 'XIcon',
                                variant: 'danger',
                                text: res.data.message,
                            },
                        })
                    }
                })
        },
    },

}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
